import React from "react"
import LayoutAsset from "../../components/LayoutAsset"
import { Link } from "gatsby"

export const AssetInfo = {
   title:"RHYTHM TIMELINE 2",
   description: "Create a rhythm game in no time by using the timeline editor to place notes!",
   link:"/Assets/RhythmTimeline" ,
   boxImage:"Assets/UnityTools/RhythmTimeline/_Box.png",
   bannerImage:"Assets/UnityTools/RhythmTimeline/_Banner.png",
   primaryButton: { 
      label: "BUY",
      href: "https://assetstore.unity.com/packages/slug/310749?aid=1101liAaC&pubref=website&utm_source=aff"
   },
   demos: {
    itchio: "https://dypsloom.itch.io/rhythm-timeline" 
   },
   images: [
      {
      original: 'Assets/UnityTools/RhythmTimeline/_Box.png',
      thumbnail: 'Assets/UnityTools/RhythmTimeline/_Box.png',
      originalAlt : 'Rhythm Timeline',
      thumbnailAlt: 'Rhythm Timeline'
      },
      {
      original: 'Assets/UnityTools/RhythmTimeline/Publishing/Track Editor Using Timeline.PNG',
      thumbnail: 'Assets/UnityTools/RhythmTimeline/Publishing/Track Editor Using Timeline.PNG',
      description: 'Use the Timeline Editor to place notes.',
      originalAlt : 'Use the Timeline Editor to place notes',
      thumbnailAlt: 'Use the Timeline Editor to place notes'
      },
      {
         original: 'Assets/UnityTools/RhythmTimeline/Publishing/RhythmTimelineAssetStoreScreenshots_Demos.png',
         thumbnail: 'Assets/UnityTools/RhythmTimeline/Publishing/RhythmTimelineAssetStoreScreenshots_Demos.png',
         description: '3 different styles of play, all included as playable demos.',
         originalAlt : '3 different styles of play, all included as playable demos.',
         thumbnailAlt: '3 different styles of play, all included as playable demos.'
      },
      {
         original: 'Assets/UnityTools/RhythmTimeline/Publishing/RhythmTimelineAssetStoreScreenshots_Input.png',
         thumbnail: 'Assets/UnityTools/RhythmTimeline/Publishing/RhythmTimelineAssetStoreScreenshots_Input.png',
         description: 'Flexible input system to support all types of gameplay.',
         originalAlt : 'Flexible input system to support all types of gameplay.',
         thumbnailAlt: 'Flexible input system to support all types of gameplay.'
      },
      {
         original: 'Assets/UnityTools/RhythmTimeline/Publishing/RhythmTimelineAssetStoreScreenshots_MIDI.png',
         thumbnail: 'Assets/UnityTools/RhythmTimeline/Publishing/RhythmTimelineAssetStoreScreenshots_MIDI.png',
         description: 'MIDI import support with note generators to onvert midi tracks to rhythm timeline tracks.',
         originalAlt : 'MIDI import support with note generators to onvert midi tracks to rhythm timeline tracks.',
         thumbnailAlt: 'MIDI import support with note generators to onvert midi tracks to rhythm timeline tracks.'
      },
      {
      original: 'Assets/UnityTools/RhythmTimeline/Documentation/V1/RhythmTimlineEditorWithGameWindow.PNG',
      thumbnail: 'Assets/UnityTools/RhythmTimeline/Documentation/V1/RhythmTimlineEditorWithGameWindow.PNG',
      description: 'Use the Timeline Editor to place notes.',
      originalAlt : 'Use the Timeline Editor to place notes',
      thumbnailAlt: 'Use the Timeline Editor to place notes'
      },
      {
      original: 'Assets/UnityTools/RhythmTimeline/Documentation/V1/GamePlayView.PNG',
      thumbnail: 'Assets/UnityTools/RhythmTimeline/Documentation/V1/GamePlayView.PNG',
      description: 'Demo includes 8 original sound tracks.',
      originalAlt : 'Demo includes 8 original sound tracks',
      thumbnailAlt: 'Demo includes 8 original sound tracks'
      },
      {
      original: 'Assets/UnityTools/RhythmTimeline/Documentation/V1/AddClipFromNoteDefinition.PNG',
      thumbnail: 'Assets/UnityTools/RhythmTimeline/Documentation/V1/AddClipFromNoteDefinition.PNG',
      description: 'Add Clips from Note Definitions, which reference the Note prefab. Clips are customizable.',
      originalAlt : 'Add Clips from Note Definitions',
      thumbnailAlt: 'Add Clips from Note Definitions'
      },
      {
      original: 'Assets/UnityTools/RhythmTimeline/Documentation/V1/TimelineEditorSnapping.PNG',
      thumbnail: 'Assets/UnityTools/RhythmTimeline/Documentation/V1/TimelineEditorSnapping.PNG',
      description: 'Snap Clips to the bpm using the Tempo track.',
      originalAlt : 'Snap Clips to place using the Tempo track',
      thumbnailAlt: 'Snap Clips to place using the Tempo track'
      },
      {
      original: 'Assets/UnityTools/RhythmTimeline/Publishing/4 Note Types.PNG',
      thumbnail: 'Assets/UnityTools/RhythmTimeline/Publishing/4 Note Types.PNG',
      originalAlt : '4 Note Types Come included (Tap, Hold, Swipe, Counter)',
      thumbnailAlt: '4 Note Types Come included (Tap, Hold, Swipe, Counter)'
      }
    ],
   documentationLink:"/Documentation/RhythmTimeline/Introduction",
   //forumLink:"/Forum/",
   videosLink:"https://youtu.be/ITMdPYpkBo4"
}

export default () => (
  <LayoutAsset  assetInfo={AssetInfo}>
     <h1>Rhythm Timeline 2</h1>

     <p>
        Rhythm Timeline 2 is an asset that lets you create a rhythm game in no time.
        It uses the Timeline editor to place your notes on tracks. Notes are spawned using a pooling system and move in sync with the timeline play head even in edit mode.
      </p>
      <p>
        By using prediction, error correction and interpolation we can spawn and move the notes with smoothly and accurately compared to the Digital Signal Processing (DSP) time.
        In addition you will be able to create your own custom notes with different behaviors with some simple scripts. Out of the box you'll have tap, hold, counter-tap and swipe.
        Placing these notes and customizing them in the scene and in the timeline editor is a breeze. 
     </p>
  </LayoutAsset>
)